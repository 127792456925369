@import '../../styles/variables/references';

.card {
    border: 1px solid $ms-color-card-border;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);  
    background-color: $ms-color-card-background;
    .card-title{
        color:#0068b7;
    } 
}
.card-body {
    background-color: white;
    padding: 10px;
}

.card-header {
    padding: 10px;
}
