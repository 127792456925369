@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.pager{   
    display: flex;

    .page-size-selection{
        vertical-align:middle;
        padding-top: 1px;

        select{
            height: 25px;
        }
    }

    .pagination-label{
        padding-left: 10px;
        padding-right: 10px;
        vertical-align:middle;
        padding-top: 4px;
    }

    .pagination{   
        display: inherit;
        margin-left: auto;

        .pageno-input {
            vertical-align:middle;
            padding-right: 20px;
            padding-left: 10px;
            input{
                width: 56px;
                height: 20px;
                font-size: 13px;
            }
        }

       
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
        }

        ul a {
            height: 24px;
            width: 24px;
            border: 1px solid #ccc;        
            display: flex;        
            justify-content: center;
            align-items: center;
            text-align: center;
            text-decoration: none;
            font-size: 14px;
            color: $ms-color-black;
            position: relative;
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
        }     

        a::before a::after{
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            content: "";
            position: absolute;
            height: 50%;
            background-color: #aaa;
        }
        a:hover {
            background-color: #0079c1;
            color: #ffffff;
            border: 1px solid #0079c1;
        }

        a:hover::before, a:hover::after {
            background-color: #fff; 
        }
        a.active {
            background-color: #0079c1;
            color: #fff;
            border: 1px solid #0079c1; 
        } 
        .pages
        {
          .page-item>a:focus , 
          .page-item>a.active:focus{
          border:1px solid black;
          }
  
          .page-item.disabled:focus
          {
            outline:0px solid;
          }
        } 
    }
}
