.loading {
    position:fixed;
    top:40%;
    right:0px;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-position:center;
    z-index : 101;
    opacity: 1;
}

.panel-loading {
    position:absolute;
    top:40%;
    right:0px;
    width:100%;
    background-repeat:no-repeat;
    background-position:center;
    z-index : 101;
    opacity: 1;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}