@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.search-result{    
    margin-top: 5px;
    .paddLft5
    {
        padding-left: 5px;
    }
    
    .search-result-status{
        font-size: 22px;
        margin: 10px 0px 0px 0px;        
        padding-bottom: 4px;        
    }

    .search-result-data{
        .ms-Grid-row
        {
          margin: 0px;
        }      
        clear: both;
        .pager-data{
            margin-top: 10px;
            padding: 0px;
            .pager
            {
                padding-left: 0px;
            }
            //For small screen
            @media (min-width: 320px) and (max-width:479px){
                .pager{
                    display: inline-block;
                    .pagination-label,.pageno-input{
                        padding-left: 0px !important;
                    }
                }
            }
            //For Medium sized Screen
            @media (min-width: 480px) and (max-width:639px){
                .pager{
                    display: inline-block;
                    .pagination-label, .pageno-input{
                        padding-left: 0px;
                    }
                }
            }
            //For Large sized Screen
            @media (min-width: 640px) and (max-width:768px){
                .pager{
                    display: inline-block;
                    .pagination-label, .pageno-input{
                        padding-left: 0px;
                    }
                }
            }

        }
    }  

    .search-filters{
        margin-top: 10px;
        border-bottom: 1px solid #605e5c;        
        .search-filter{
           
            .accordion{
               border: none;
            }
            .accordion-header:focus{
                outline: none;
                border:1px solid grey;
            }
        }
        .search-filter ~.search-filter{
            border-top:1px solid $ms-color-neutralTertiaryAlt;
        } 
    }

    .search-filter-icon{
        width:25px;
        font-size:16px;
        cursor: pointer;
    }
    .search-input-paramters{
        margin: 5px 0px 5px 0px;
        .label {
            font-size: 14px;
            color: #626262;
        }
        .value {
            font-size: 14px;
            color: $ms-color-black;
        }  
    }
    .no-search-result-status{
        font-size: 22px;
        margin: 0px 0px 10px 0px;        
        padding-bottom: 6px;
    }
    

    .search-filters-header{
        border-bottom: 1px solid #605e5c;
        font-size: 20px;
        padding-bottom: 5px;
        display: flex;
        i{
            font-size:16px;   
            display: inline;
            padding-right:10px;            
        }

        .search-filters-header-actions{
            margin-left: auto;
            font-size: 16px;
            a{            
                text-decoration: none;
            }
        }  
        //For small screen
         @media (min-width: 320px) and (max-width:479px){
            .filter-option {
                width: 25px;
                overflow: hidden;
                white-space: pre;
                margin-right: 14px;
                font-size: 20px;
                margin-top: 0px;
            }
        }
        //For Medium sized Screen
        @media (min-width: 480px) and (max-width:639px){
            .filter-option {
                width: 25px;
                overflow: hidden;
                white-space: pre;
                margin-right: 14px;
                font-size: 20px;
                margin-top: 0px;
            }
        }      
    }
    .action-buttons{
        display: flex;
        margin-bottom: 1px;

        .sort-by-filter{
           padding-right: 3px;
        }

        .select-all-item{
            padding-top: 12px;
            
        }
        .float-right-content{
            display:flex;
        }
         //For small screen
        @media (min-width: 320px) and (max-width:479px){
            .float-right-content{
                margin-top:10px;
            }
        }
        
        //For small screen
        @media (min-width: 320px) and (max-width:479px){
            .select-all-item{
                margin-top: 40px;
            }
        }
        //For small screen
    @media (min-width: 320px) and (max-width:479px){
                    .download-link{
                        width: 29px;
                        overflow: hidden;
                        white-space: pre;
                        margin-right: 14px;
                        font-size: 20px;
                        margin-top: 0px;
            }
        }
        //For Medium sized Screen
        @media (min-width: 480px) and (max-width:639px){
            .download-link
            {
                width: 29px;
                overflow: hidden;
                white-space: pre;
                margin-right: 14px;
                font-size: 20px;
                margin-top: 0px;
            }
        }

        a{            
            text-decoration: none;
            margin-top: 5px;
            margin-right: 10px;
        }

        .search-filters-show-header{
            font-size: 20px;
            margin-top: 10px;
            padding-right:10px;    
            i{            
                font-size:16px;   
                display: inline;
                padding-right:10px;      
            } 
            
            //IE related CSS
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                .search-filters-header-text {
                    display: inline-flex;
                }
            }

            //For Edge browser
            @supports (-ms-ime-align: auto) {
                .search-filters-header-text {
                    display: inline-flex;
                }  
            }

        }

        .action-buttons-right{
            margin-left:auto;
            display: flex;
        }
    }
    .search-filter{
        margin: 5px 0px;
        

        .search-filter-name{
            font-size: 18px;
            margin: 0px 0px 5px 0px;  
            overflow: hidden; 
            text-overflow: ellipsis;
            //For small screen
            @media (min-width: 320px) and (max-width:479px)
            {
                max-width: 100px;
            }
            //For Medium sized Screen
            @media (min-width: 480px) and (max-width:639px)
            {
                max-width: 100px;
            }
            //For Large sized Screen
            @media (min-width: 640px) and (max-width:990px)
            {
                max-width: 100px;
            }
            
        }
        .search-filter-items{
            margin: 5px;
            .search-filter-item{
                display: flex;
                margin: 3px 0px 3px 0px;
                .search-filter-item-checkbox{
                    
                }
                .search-filter-item-count{
                    margin-left: auto;
                    .mrgRgt8
                    {
                        margin-right: 8px;
                    }
                }
            }

        }
    }
    .filter-badge{     
        margin: 10px 0px 10px 0px;
        .filter-badge-item{
            border: 1px solid $ms-color-neutralTertiaryAlt;             
            margin: 3px;  
            display: inline-block;  
            .filter-badge-item-text {                
                max-width: 150px;           
                vertical-align: middle;
                padding:5px;
                font-size: 14px;
            }
            .filter-badge-item-btn {              
               padding: 0px;
               margin: 0px;
            }            
        }
    }
}

.selected-items{  
    padding: 10px 10px 10px 8px;
    .remove-all-button{
        margin: 0px;
        text-decoration: none;
        font-size: 15px;
        padding-left:5px; 
    }

    .selected-item-badge{
        padding: 0px 0px 0px 5px;
        margin: 2px;
        border: 1px solid $ms-color-neutralTertiaryAlt;  
        vertical-align: middle;     
        display: inline-block;
        max-width: 260px;
        
        .selected-item-badge-text {  
            padding: 6px 0px 4px 0px;
            vertical-align: sub;
            max-width: 224px;          
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;           
            display: inline-block;
            font-size: 14px;            
            .badge-label{
                color: #626262;
            }
        }
        .selected-item-badge-btn {              
           padding: 0px;
           margin: 0px;
        }                 
    }
    .accordion 
    {
        cursor: pointer;
      border-top:0px solid #CCC;     
      .accordion-header
      {        
        background: #f1f1f1;
        border: 1px solid #CCC;      
        .sku-console-section-heading
        {
          color: #626262;
          font-size: 16px;
          padding: 0px 0px 0px 5px;
          font-weight:600;
        }
        .store-count-section-heading
        {
          color: #626262;
          font-size: 16px;
          padding: 0px 0px 0px 10px;
          font-weight:400;
          .attach-count ,.deatch-count
          {
            font-weight: 600;
            
          }
        }
        .accordion-header-text
        {
          width: 100%;
          border: 0px solid #CCC;   
          
        }
        .accordion-header-icon
        {
        vertical-align: middle;         
        color: #626262;
        }
      }
      .accordion-header-modified
      {
        border-left:5px solid #FA922A; 
      }
      .accordion-body
      {
        padding: 0px;
        border-left: 1px solid #CCC;
        border-right: 1px solid #CCC;
        border-bottom: 1px solid #CCC;      
        .selected-items-section
        {
            padding: 5px; 
        }
      }
    }
}

//For small screen
@media (min-width: 320px) and (max-width:479px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
    .filter-badge-item-text{
        max-width:350px !important;
        flex-grow:1;
    }
    .filter-badge-item{
        display:inline-flex;
        padding: 0px !important;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
//For Medium sized Screen
@media (min-width: 480px) and (max-width:639px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
    .filter-badge-item-text{
        max-width:350px !important;
        flex-grow:1;
    }
    .filter-badge-item{
        display:inline-flex;
        padding: 0px !important;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
//For Large sized Screen
@media (min-width: 640px) and (max-width:1023px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
    .filter-badge-item-text{
        max-width:350px !important;
        flex-grow:1;
    }
    .filter-badge-item{
        display:inline-flex;
        padding: 0px !important;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
//For Extra Large screen
@media (min-width: 1024px) and (max-width:1365px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
    .filter-badge-item-text{
        max-width:350px !important;
        flex-grow:1;
    }
    .filter-badge-item{
        display:inline-flex;
        padding: 0px !important;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
}