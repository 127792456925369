.footer {
    position: absolute;
    padding: 5px 0;
    bottom: 0;
    width: 100%;

    background-color: rgb(33, 33, 33);
    .footer-lang-dropdown{
        .ms-Button-icon{
            color: white
        }
    }
    .footer-navigation{
        .footer-trademark{
            color: white;
            margin-top: -3px;
           p{
                font-size: 14px;
           }
        }
    }
    
}


@media only screen and (max-width: 780px) {
    .footer-lang-dropdown{
        display: flex;
        justify-content: center;
        .ms-Button-icon{
            color: white
        }
    }
    
    .footer-navigation{
        display: flex;
        flex-wrap: wrap;

        justify-content: center;
        .footer-trademark{
            display: flex;
        }
    }
}

@media only screen and (min-width: 780px) {

    .footer{
        .footer-lang-dropdown{
            float: left;
        }
        .footer-navigation{
            float: right;
            display: flex;
            .footer-trademark{
                display: flex;
            }
        }
    }
}
