@import '../../resources/styles/variables/references';

.wizard-step{
    padding: 0px;
    font-family: $ms-font-family-fallbacks;

    .wizard-step-header{       
        font-weight: lighter;
        margin-top: 20px;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
        >h2 {
            font-size: $ms-font-size-xxl;
            font-weight: lighter;         
        }
    }

    .wizard-step-body{
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $ms-color-neutralTertiaryAlt;
    }
    .wizard-step-footer{
        .button-group{
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap
        }
        button {           
            font-size: $ms-font-size-m-plus;   
            min-width: 120px;       
            margin-left: 10px;           
        }
    }
}



@media (max-width: 768px) {
    .wizard-step{
        .wizard-step-footer{
            .button-group{
                .full-width{
                    width: 100%;
                    margin: 0px  0px 10px 10px;
                }
                display: flex;
                justify-content: space-between;
            }
        }
    }
}