
@media (min-width: 768px) {
    .equal-height-card-wrapper{
    display: flex;
    .card{
        height: 100%;
        .card-body{
            display: flex;
            flex-direction: column;
        }
    }
}
}