$font-size-base: 1rem;
$button-line-height: 2.75;
$line-height-minus: 0.1875rem;
$h1-font-size: $font-size-base * 1.5;
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1342px);
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1230px, xxl:1590px );

// Mobile font-face sizes
$h1-font-sm-size: 1.5rem;
$h2-font-sm-size: 1.125rem;
$h3-font-sm-size: 1.125rem;
$h4-font-sm-size: 1.125rem;
$h5-font-sm-size: $font-size-base;
$h6-font-sm-size: $font-size-base;
$button-font-sm-size: 0.9375rem;
$caption-font-sm-size: 0.875rem;
$footer-font-sm-size: 0.75rem; //12px
$adjustment-sm-size: 0.6875rem; //11px
$small-font-sm-size: 0.625rem; //10px

// Desktop font-face sizes
$h1-font-lg-size: 2.125rem;
$h2-font-lg-size: 1.75rem;
$h3-font-lg-size: 1.5rem;
$h4-font-lg-size: 1.25rem;
$h5-font-lg-size: 1.125rem;
$h6-font-lg-size: $font-size-base;
$button-font-lg-size: 0.9375rem;
$caption-font-lg-size: 0.875rem;
$footer-font-lg-size: 0.75rem;
$small-font-lg-size: 0.625rem;

.global-header-wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin: 5px;

    .user-dropdown {
        float: right;

        .ms-CommandBar {
            background-color: white;

            .ms-CommandBarItem-link {
                background-color: white;
            }
        }
    }
}

.navbar-brand {
    color: #2F2F2F;

    &.microsoft {
        margin: 0px 0px 0px -5px;
        border: 5px solid white;
        float: left;

        img {
            height: 24px;
            width: auto;
            float: left;
        }
    }

    &.oem-doc {
        &:link, &:active, &:hover, &:visited {
            font-size: $h5-font-lg-size;
            text-decoration: none;
        }
    }
}

@media only screen and (max-width: 480px) {
    .global-header-wrapper {
        .navbar-brand {
            &.microsoft {
                height: 34px;
                width: 34px;
                overflow: hidden;
                padding: 0;
                margin: 0px 0px 0px -5px;
                border: 5px solid #fff;
                float: left;

                img {
                    height: 24px;
                    width: auto;
                    float: left;
                    padding: 3px;
                }
            }

            &.oem-doc {
                &:link, &:active, &:hover, &:visited {
                    font-size: $h6-font-lg-size;
                    text-decoration: none;
                    margin: auto !important;
                    display: inline-block;
                    padding-top: 0.3125rem;
                    padding-bottom: 0.3125rem;
                }
            }
        }

        .user-dropdown {
            margin-left: auto !important;
            margin-right: -5px;
            position: initial;
            display: block;
            width: 80px;

            .ms-Button-label {
                width: 0px;
                padding: 0px;
                margin: 0px;
                overflow: hidden;
            }
        }
    }
}