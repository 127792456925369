@import '../../styles/variables/references';

.accordion{
    padding: 0px;
    width: 100%;
    &.expanded{
        .accordion-header{
            .accordion-header-text{
                font-weight: 400;
            }
        }
    }
    &.search-accordion{
        border: none;
    }

    .accordion-header{
        width: 100%;        
        text-align: left;
        display: flex;      
        div:nth-child(1){
            
        }
        div:nth-child(2){
            margin-left: auto;            
            i{
                font-size: 80%;
                font-weight: bold;
            }
        }          
        .accordion-header-icon{
            cursor: pointer;
            font-weight: bold;           
            vertical-align: top;
            padding: 5px;  
            margin: auto;
            font-size: 20px;             
            margin-right: 0;   
        }
        .accordion-header-text{            
            margin:0px;
            padding: 12px 0px;
            font-size:18px;
            font-weight: lighter;
        }
    }
    
    .accordion-body{
        width: 100%;
        text-align: left;        
        padding: 4px;
    }
    
    .accordion-body-hide{
        display: none;
    }
    border-top: 1px solid $ms-color-neutralTertiaryAlt;
}




